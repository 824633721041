// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-appearances-js": () => import("./../../../src/pages/appearances.js" /* webpackChunkName: "component---src-pages-appearances-js" */),
  "component---src-pages-corporate-finance-js": () => import("./../../../src/pages/corporateFinance.js" /* webpackChunkName: "component---src-pages-corporate-finance-js" */),
  "component---src-pages-federal-register-js": () => import("./../../../src/pages/federalRegister.js" /* webpackChunkName: "component---src-pages-federal-register-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-management-js": () => import("./../../../src/pages/investmentManagement.js" /* webpackChunkName: "component---src-pages-investment-management-js" */),
  "component---src-pages-investor-alerts-js": () => import("./../../../src/pages/investorAlerts.js" /* webpackChunkName: "component---src-pages-investor-alerts-js" */),
  "component---src-pages-litigation-js": () => import("./../../../src/pages/litigation.js" /* webpackChunkName: "component---src-pages-litigation-js" */),
  "component---src-pages-meetings-js": () => import("./../../../src/pages/meetings.js" /* webpackChunkName: "component---src-pages-meetings-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-other-orders-notices-js": () => import("./../../../src/pages/otherOrdersNotices.js" /* webpackChunkName: "component---src-pages-other-orders-notices-js" */),
  "component---src-pages-proposed-rules-js": () => import("./../../../src/pages/proposedRules.js" /* webpackChunkName: "component---src-pages-proposed-rules-js" */),
  "component---src-pages-speeches-js": () => import("./../../../src/pages/speeches.js" /* webpackChunkName: "component---src-pages-speeches-js" */),
  "component---src-pages-statements-js": () => import("./../../../src/pages/statements.js" /* webpackChunkName: "component---src-pages-statements-js" */),
  "component---src-pages-testimony-js": () => import("./../../../src/pages/testimony.js" /* webpackChunkName: "component---src-pages-testimony-js" */),
  "component---src-pages-trading-suspensions-js": () => import("./../../../src/pages/tradingSuspensions.js" /* webpackChunkName: "component---src-pages-trading-suspensions-js" */),
  "component---src-pages-week-js": () => import("./../../../src/pages/week.js" /* webpackChunkName: "component---src-pages-week-js" */),
  "component---src-pages-yesterday-js": () => import("./../../../src/pages/yesterday.js" /* webpackChunkName: "component---src-pages-yesterday-js" */)
}

